import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router'
import './admission.css'
import parser from 'html-react-parser';
import axios from 'axios';
import Fees from './fees';
import Admit from './admit';
import Year from './year';

export default function Admission() {

    const { name } = useParams();

    return (
        <div>
            <div className='onadmission'>
                <div className='mainTopic'>Admissions</div>
            </div>
            <Container>
            {(name==='Process')?<Admit/>:(name==='Fees')?<Fees/>:<Year/>}
            </Container>
        </div>
    )
}
