import React from 'react';

export default function Admit() {
  return <div>
        <span className='headText' style={{color:'rgba(0,0,0,0.7)', marginLeft:'0'}}>Application Process</span><hr/>
      <p style={{textAlign:'justify'}}>
     <span className='head'>How to submit an application</span>
     <br/>
         <a href='https://www.evelynhone.ac.zm/ehcadmin/appdetails.aspx'>Click here</a>, follow the steps and enter your correct details:
         <br/>  <br/> <span className='head'>How to make a payment</span><br/>
         Your can deposit the application fee into the following account No: 3215088636019, Atlas Mara, Kamwala Branch. Your School ID will be Your Application Number starting with the prefix EH. e.g. if your Application Number is 001322, when filling the deposit slip in indicate your Student ID as EH001322. Scan your deposit slip and attach on your application files as proof of payment.
          You can pay using the School Pay Site
         <a href='https://schoolpay.co.zm/payfees/default/select-channel'> here </a>
         . Select whether to use MTN Mobile Money, Airtel Money or Zamtel Kwacha. On the Payment code, or Registration Number enter your Application Number starting with EH, eg EH0012324. Enter the amount (165) and Indicate the mobile account phone number to use, then click on Pay Fee. Confirm the payment and enter the Pin for your mobile money account from your phone.
         if this is done successfully the finance status of your application will change from 'Not Paid' to 'Paid'.
         You can also make the payment directly from your mobile phone using your mobile money account. On Airtel *115#, MTN *305# on Zamtel dial *344#.
         On the student ID enter your application number starting with EH, eg EH0012324. The amount for application fees is 165.
         <br/> <br/> <span className='head'>Application progress</span><br/>
         After you submit your first application, the system will generate a login code for you. When you go to 
            <a href='#'> my Applications</a> 
            enter your login code. This will show you a list of pending applications. Select the application by clicking on the your name.
         </p>
 
  </div>;
}
