import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import './school.css'
import '../App.css'
import axios from 'axios';
import { useParams } from "react-router-dom";

export default function Schools() {

    
    const [schools, setSchools] = useState([]);
    const [progs, setProgs] = useState([]);
    
    const { id } = useParams();

    
    
    const loadSchools = async () => {
        
       const school = await axios.get('https://ehcapps.evelynhone.ac.zm/webBackend/school.php?schools='+id);

       setSchools(school.data);
                       
    }

    const sxu = schools.filter(item => item.sid===id)
    
        
    const loadProgs = async () => {
        
        const prog = await axios.get('https://ehcapps.evelynhone.ac.zm/webBackend/school.php?programs='+id);
 
        setProgs(prog.data);
        
                        
     }
 
     const program = progs.filter(item => item.SchoolID===id)

     
    

    useEffect(() => {

        loadSchools();
        loadProgs();

    },[])


    return (
        <div>
            <div className='mutu'>
                <span className='mainTopic'>Schools</span>
            </div>

            <Container>
                
           { sxu.map((res)=>( 
               <>
                <div className='headText' style={{color:'rgba(0,0,0,0.7)', marginLeft:'0'}}>{res.schoolName}</div>

                <span className='head'>Overview</span>  
                <br/>
                {res.overview}
                <hr/>
                <span className='head'>Vision</span>
                <br/>
                {res.vision}
                <hr/>
                
                <span className='head'>Contacts</span>
                <br/>
                    <span style={{fontWeight:'500'}}>Head of school</span>
                    <br/>
                    {res.headOfSchool}
                    <br/>
                    {res.email}
                    <br/>
                    {res.phone}
                    <hr/>
                </>
               )) }
                    <span className='head'>Progams</span>
               {
                   program.map((res)=>(
                    <>
                        
                        <br/>
                        {res.ProgramName}
                    </>

                   ))
               }
            </Container>
            
        </div>
    )
}
