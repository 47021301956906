import React from 'react'
import { Table } from 'react-bootstrap'

export default function Lib() {
  return (
    <div>
        <h5>ABOUT OUR LIBRARY</h5>

        <p>Evelyn Hone College library has been in existence since the college was first opened in 1963. Its development and structure is very much tied up with the development and structure of the college itself. It plays an essential role in the educational function of the college. Its main function is to provide an efficient service to both the staff and the students. In 1975 the Zambia library services building which is now the main library was incorporated into the Evelyn hone college and later the Zambia library service moved to the educational service center.</p>

        <p>Evelyn Hone college library provides physical services to its patrons just like any other libraries. Such services include the reference services through the reference desk, consultation services. It also provides online services which are done by facilitating access to several electronic journals, the OPAC and so on. The majority of e-journals are electronic versions of print journals that can be accessed via the internet. The Evelyn hone college library provides access to e-journals through ZALICO with the support from INASP (PERII) and elFL. </p>

        <p>Evelyn Hone College consists of three libraries in different physical locations, namely, Main Library, Reference Library and the Media Library. The Evelyn Hone College Libraries are committed to supporting the teaching, research and outreach efforts of the college and to serving the community through its collections, preservation efforts and access to information in all of its various formats.</p>
   
        <h5>MISSION</h5>

        <p>To meet the information needs for study, teaching and research in applied arts, commerce, science and technology to local and international students in order to fulfil requirements of the labor market.</p>

        <h5>LIBRARY SERVICES</h5>

        <ul>
            <li>Online reference service </li>
            <li>Book lending </li>
            <li>Access to E-books </li>
            <li>Access to E-newspapers</li>
            <li>Access to Past Papers </li>
            <li>Access to Novels</li>
            <li>Access to Audio Books </li>
            <li>Access to Video Tutorials </li>
        </ul>

        <h5>TRAININGS OFFERED</h5>
        <ul>
            <li>Referencing and Citation using Zotero , Mendeley and Word</li>
            <li>Data Entry and Analysis using SPSS and STATA</li>
            <li>KOHA training </li>
            <li>RDA Training </li>
        </ul>

        <h5>OPERATING HOURS </h5>
            Monday to Friday  <br />
            08:30-21:00 <br />
            Saturday <br />
            08:00-12:30 <br />
            Sundays: Closed <br />

            <h5>CONTACTS</h5>
            Email: <a href="mailto:ehclibrary@evelynhone.edu.zm">ehclibrary@evelynhone.edu.zm</a> <br />
            Facebook: <a href="https://www.facebook.com/Evelyn-Hone-College-Library-436070289773717">https://www.facebook.com/Evelyn-Hone-College-Library-436070289773717</a>
                <br />
            <h5>ONLINE LEARNING </h5>

        <ol>
            <li> <a href="https://www.edx.org/ ">https://www.edx.org/ </a> <br />
            edX provide more than 2000 free online courses from MIT, Harvard, Boston University etc. They cover Business & Management, Computer Science, Humanities, Design, Arts & Culture etc. 
            </li>

            <li>
            <a href="https://www.coursera.org/">https://www.coursera.org/</a> <br />
            Coursera with a collaboration of 200+ leading universities and companies like IBM, Google, University of Michigan, etc offers free online courses on Computer Science, Health and Medicine, Programming, Engineering, Data Science etc. 
            </li>
            <li>
            <a href="https://www.codeacademy.com">https://www.codeacademy.com</a> <br />
            Codeacademy is specifically for people who want to learn how to code with various languages that includes HTML & CSS, Python, C++, Java, Ruby, C# and so on. It provides a live practice demo and dashboard for monitoring your progress. 
            </li>

            <li>
            <a href="https://www.open.edu">https://www.open.edu</a> <br />
            Open University offers around free 1000 courses and a student will be given a certificate of participation upon completion. They cover childhood and youth studies education careers, health and sports, society, politics and law etc. 

            </li>

            <li>
            <a href="https://www.khanacademy.com">https://www.khanacademy.com</a> <br />
            The Khan Academy offers free online courses for  children aged from 2-18 years and adults. Their subjects include Life Skills, Economics, Reading and Language, Arts, Test Prep and so on. 

            </li>

            <li>
            <a href="https://www.ed.ted.com">https://www.ed.ted.com</a> <br />
            TED-ED has a growing library of original animated videos that support their learning and are mostly videos of 15 minutes. They cover Philosophy and Religion, Psychology, Teaching & Education, Social Studies, Thinking and Learning , The Arts, etc. 
            </li>

            <li>
            <a href="https://www.alison.com">https://www.alison.com</a> <br />
            Alison offers free online courses on Marketing, Humanities, Language, Mathematics, Technology and so on. 
            </li>

            <li>
            <a href="https://www.online-learning.harvard.edu">https://www.online-learning.harvard.edu</a> <br />
            Harvard now offers a variety of free courses on different subjects through their online platform. These courses include Introduction to Artificial Intelligence, Game Development, Digital Humanities, Fundamentals of neuroscience, to mention a few. 

            </li>
            <li>
            <a href="https://www.learndigital.withgoogle.com">https://www.learndigital.withgoogle.com</a> <br />
            Google offers a Free Digital Marketing course for one to study fundamentals of digital marketing and google products. Google awards an accredited certificate after completion. 
            </li>

            <li>
            <a href="https://www.futurelearn.com">https://www.futurelearn.com</a> <br />
            Future Learn offers free online courses on Politics & Society, Psychology & Mental Health, Creative Arts a& Media, Nature & Environment, etc
            </li>

        </ol>

        <h5>RemoteXs</h5>
        <a href="https://zalico.remotexs.co/">https://zalico.remotexs.co/</a> <br />
        RemoteXs enables all leading institutions (academic or research) in optimizing the usage of precious eResources and nurturing research interests of faculty and their students.

RemoteXs has an ability to provide secured access to scattered eResources of the institution, bringing them under one umbrella, along with subscribed eJournals, eBooks and all other eContent, that too anytime …from anywhere.

            <h5>PDF DRIVE </h5>

            <a href="https://www.pdfdrive.com/">https://www.pdfdrive.com/</a> <br />
            PDF Drive is the search engine for PDF files. It has over 70 Million eBooks for download for free.

            

    </div>
  )
}
