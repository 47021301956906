import React, {useState} from "react"; 
import Carousel from 'react-bootstrap/Carousel';
import './carousel.css';

const data = [

    {
      image:require('./images/IMG_0619.JPG'), 
      caption:"Register",
      description:"'Even the greatest were beginners. Don’t be afraid to take that first step'"
     },
     {
      image:require('./images/webscroll.JPG'), 
      caption:"Apply now",
      description:"'It never gets easier. You just get better'"
     },
     {
      image: require('./images/IMG_0821.JPG'), 
      caption:"Apply now",
      description:"'The way to get started is to quit talking and begin doing'"
     }
]

function HomeCarousel() {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect}>
       {data.map((slide, i) => {
        return (
          <Carousel.Item>        
        <img
          className="d-block w-100"
          src={slide.image}
          style={{height:'500px'}}
          alt="slider image"
        />
        <Carousel.Caption>
          <div style={{backgroundColor:'rgba(0,0,0,0.5)', padding:'10px', width:'50%', marginLeft:'25%', borderRadius:'10px'}}>
          <button style={{border:'0', padding:'5px', fontSize:'20px', fontWeight:'1000' , backgroundColor:'#ff7f27', color:'white', borderRadius:'5px'}}>{slide.caption}</button>
          <br/>
          
          <p style={{fontWeight:'1000', margin:'5px'}}>{slide.description}</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
        )
      })}
      
    </Carousel>
  );
}
export default HomeCarousel;