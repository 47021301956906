import React from 'react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Row, Col, Table, Button } from 'react-bootstrap';

export default function Fees() {

    const [fees, setFees] = useState([]);
    const [sect, setSect] = useState([]);
    const [program, setProgram] = useState([]);

    const loadFees = async ()=>{
        const fee = await axios.get('https://ehcapps.evelynhone.ac.zm/webBackend/home.php?fees');
        setFees(fee.data)
        console.log(fee.data)
           
    }

    var schools = fees.map((res) =>res.SchoolName)
    var sections = []
    var programs = []
    var feez = []
    
    schools = [...new Set(schools)]
   

    useEffect(()=>{
        loadFees();
    },[])

    function showSection(name){
        
        sections = fees.filter(a=>a.SchoolName==name)
        sections = sections.map((res) =>res.SectionNAme)
        sections = [...new Set(sections)]
        setSect(sections)
    }

  
    function showProg(name){
        
        programs =  fees.filter(a=>a.SectionNAme==name)
        programs = programs.map((res) =>res.ProgramName)
        programs = [...new Set(programs)]

       feez = programs.map((res) => (fees.filter(a=>a.ProgramName==res)))
    
       var final = [].concat.apply([], feez);
        setProgram(final)

    }   


  return <div>
            <span className='headText' style={{color:'rgba(0,0,0,0.7)', marginLeft:'0'}}>Academic Fees</span><hr/>
            <Row>
              <Col>
            {schools.map((res)=>(
                <>
                   <Button variant="outline-secondary" style={{margin:'5px'}} onClick={()=>showSection(res)}> {res} </Button> <br/>
                                
                </>

            ))}
            </Col>  
            <Col>{sect.map((res)=>(
                <>
               <Button variant="outline-secondary" style={{margin:'5px'}} onClick={()=>showProg(res)}> {res} </Button> <br/>
                </>
            ))}</Col>
            
            </Row>
            <hr/>
            <Table>
                <tr><th>Program</th><th>Amount</th><th>Mode of study</th><th>Year</th></tr>
                
                {program.map((res) => (
                    <>
                        <tr><td>{res.ProgramName}</td><td>{res.Amount}</td><td>{res.StudyMode}</td><td>{res.YearOfStudy}</td></tr>
                    </>
                ))}
            </Table>

        </div>;
}
