import logo from './logo.svg';
import './App.css';
import Header from './header';
import React, { useEffect, useState } from "react";
import Footer from './footer';
import Home from './home';
import HomeCarousel from './carousel';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Schools from './schools/schools';
import About from './about/about';
import Events from './events/events';
import NotFound from './notFound/notFound';
import Admission from './admissions/admission';
import Library from './library/library';


function App() {
  return (
    <BrowserRouter>
    <Header/>
      <Routes>
          <Route index element={<Home/>} />
          <Route path="/schools/schools/:id" element={<Schools/>} />
          <Route path="/about/about" element={<About/>} />
          <Route path="/events/events" element={<Events/>} />
          <Route path="/events/events" element={<Events/>} />
          <Route path="/admissions/admission/:name" element={<Admission/>} />
          <Route path="/library/library/:name" element={<Library/>} />
          <Route path="*" element={<NotFound/>} />      
        </Routes>
        <Footer/>
  </BrowserRouter>

  );
}

export default App;
