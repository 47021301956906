import React from 'react'
import { Container } from 'react-bootstrap'
import './about.css'

export default function About() {
    return (
        <div>
            <div className='ontop1'>
                <div className='mainTopic'>About Us</div>
            </div>
            <Container>
            Evelyn Hone College of applied Arts and Commerce is the largest
             College in Zambia. The College Offers Academic and Professional
              Training in various disciplines such as Business and Management,
               Information technology and Communication, Health Sciences,
                laboratory Sciences, Art and Music, Communication Skills
                 and Library Studies, Journalism and Printing, office 
                 management. We are a total package. Our Courses are 
                 available to school leavers, workers and Companies or
                  Government Institutions who may want to train their employees.
                  <hr/>
                <span className='head'>Vision</span>
                <br/>
                To be a preferred University College of Technology for training in applied arts, commerce, science and technology.
                <hr/>
                <span className='head'>Mission</span>
                <br/>
                To provide quality training in applied arts,
                 commerce, science and technology in order to
                  increase the pool of highly skilled personnel,
                   enhance their employability and contribute to
                    economic diversification.
                    <hr/>
                <span className='head'>Values</span>
                <br/>
                    <span> Professionalism | Integrity | Transparency | Fairness | Accountability | Efficiency and Effectiveness</span>
                <hr/>
                <span className='head'>History</span>
                <br/>Evelyn Hone College of Applied Arts and Commerce was officially opened on 23rd October 1963 by his excellence Sir Evelyn Dennison Hone the last governor of Northern Rhodesia and offered its first courses in 1963. At inception, the college was called College of Further Education and offered very few courses at certificate and diploma levels. The name changed to Evelyn Hone College of Applied Arts and Commerce in 1972. The college is the largest Technical Education, Vocational and Entrepreneurship Training (TEVET) institution. It has a population of about 7,000 students, 270 members of staff and offers 41 programmes in its seven academic departments and schools. The departments and schools are Health and Applied Sciences, School of Business and Management Studies, School of Media and information Science, School of Education and Department of Open Distance and Flexible Learning (ODFL). Giving strength to all the mentioned schools and departments are Library and ICT departments. These departments offer information services to the entire institution.

The college offers academic and professional training in various disciplines such as Business and Management, Information Technology, Health Sciences, Laboratory Sciences, Art, Music, Communication Skills, Library studies, Journalism, Printing Technology, Office Management. The college has currently partnered with Binary University to offer degree programmes in Computer Science, Human Resource, Accountancy, and Business Management.
            </Container>
        </div>
    )
}
