import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './events.css'
import axios from 'axios'


export default function Events() {

    const [all, setAll] = useState([]);

    const [ev, setEv] = useState([]);

    const [ne, setNe] = useState([]);
    const [actual, setActual] = useState([]);

    const loadNews = async () => {

        const newz = await axios.get('https://ehcapps.evelynhone.ac.zm/webBackend/home.php?news=1');
        
        setAll(newz.data);
        setNe(newz.data.filter(a=>a.type==1));
        setEv(newz.data.filter(a=>a.type==2));

    
    }
    
   

    useEffect(() => {
        loadNews();    
    },[])
    
    const choose = (x) => {

         setActual(all.filter(a=>a.nid==x))
         
        
      }

    return (
        <div>
            <div className='ontop'>
                <span className='mainTopic'>News & Events</span>
            </div>
            <Container>
               
                
                <Row className="brd">

                    <Col sm={3}>
                        
                    <div className='col1'>
                          News <hr/>
                        <ul>
                            {ne.map((res)=>(
                            <li onClick={()=> choose(res.nid)} >
                            <div className='low link'>
                                
                        <div className='column'>
                        <img src={res.path}/>  
                        
                        </div>
                        <div className='colum'  style={{fontSize:'12px'}}>
                         {res.title.substring(0,20)}
                         <br/>
                        <span className='date'>{res.reg_date}</span>
                        </div>
                        </div>   
                            </li>
                            ))}
                           
                        
                        </ul>
                        </div>
                    </Col>
                <Col sm={5} className='col1'>
                {actual.map((res)=>(

                    <>
                        <img src={res.path} style={{width:'100%'}}/> 
                        <br/>
                        <br/>
                        <span className='head' style={{fontSize:'20px'}} >{res.title}</span>
                        <hr/>
                        <p>
                            {res.content}
                        </p>
                        <span className='date'>{res.reg_date}</span>
                    </>

                ))}

                </Col>
                    <Col sm={3}>


                        <div className='col1'>
                         Events <hr/>
                        <ul>
                        {ev.map((res)=>(
                            <li onClick={()=> choose(res.nid)} >
                            <div className='low link'>
                        <div className='column'>
                        <img src={res.path}/>  
                        </div>
                        <div className='colum'  style={{fontSize:'12px'}}>
                         {res.title.substring(0,20)}
                         <br/>
                        <span className='date'>{res.reg_date}</span>
                        </div>
                        </div>   
                            </li>
                            ))}
                        
                        </ul>
                        </div>

                     </Col>
                               
                </Row>
            </Container>
        </div>
    )
}
