import React, { useState, useEffect } from 'react';




export default function Year() {

  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    // Fetch the JSON object from the backend API
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch('https://evelynhone.ac.zm/hostel.php'); // Replace with your backend API endpoint
      const jsonData = await response.json();
      setData(jsonData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    // Perform search logic here based on the searchTerm and the data JSON object
    const results = data.filter((item) =>
      item.student.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
  };
  return <div>
      <span className='headText' style={{color:'rgba(0,0,0,0.7)', marginLeft:'0'}}>Provisional Bed Space Allocation 2023			
</span><hr/>
<form onSubmit={handleSearch}>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Enter student number"
          style={{border:'black 1px solid'}}
        />
        <button style={{border:'black 1px solid'}} type="submit">Search</button>
      </form>
      {searchResults.length > 0 ? (
        <div>
          <h3>Search Results:</h3>
          <ul>
            {searchResults.map((result) => (
              <li key={result.student}>{result.student} - {result.studentname}</li>
            ))}
          </ul>
        </div>
      ) : null}
  </div>;
}
