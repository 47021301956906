import React from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import './library.css'
import Lib from './lib';

export default function Library() {

  
    const { name } = useParams();
   
    var names = '';
    var data = ''
    if(name === 'publication'){
        names = 'Publications'
        data = `<h1>WIFI configurations document</h1>
                <ul>
                    <li><a href="/wifi_config.pdf" download>PC Config</a></li>
                    <li><a href="/EDUROAM_WIFI.docx" download>Phone Congif</a></li>
                </ul>`
    }else if(name === 'ebooks'){
        names = 'Library'
        data = <Lib/>
    }else if(name === 'article'){
        names = 'Articles'

        data = 'To write an article login into ur student account, goto library then articles.'
    }

  return (
    <div>
        <div className='onlib'>
            <span className='mainTopic'>Library</span>
        </div>

        <Container>
        <span className='headText' style={{color:'rgba(0,0,0,0.7)', marginLeft:'0'}}>{names}</span><hr/>
       {data}
        </Container>
        
    </div>
  );
}
