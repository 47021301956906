import {  } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { Col, Row, Container, Modal, Button } from 'react-bootstrap'
import './home.css'
import axios from 'axios'
import HomeCarousel from './carousel';
import { Link } from 'react-router-dom'
import MyVerticallyCenteredModal from './modal'

export default function Home() {

    const [news, setNews] = useState([]);
    const [events, setEvents] = useState([]);
    const [videos, setVideos] = useState([]);
    const [course, setCourses] = useState([]);
    const [vacancy, setVacancy] = useState([]);
    const [more, setMore] = useState([]);

    const [modalShow, setModalShow] = React.useState(false);

    function substr(text,num){
        num = parseInt(num);

        if(text.length > num){
            text = text.substring(0,num);
            text = text+"...";
        }
        
        return text;
    }

    const loadNews = async () => {

        const newz = await axios.get('https://ehcapps.evelynhone.ac.zm/webBackend/home.php?news=1');
        setNews(newz.data.filter(a=>a.type==1));
        setEvents(newz.data.filter(a=>a.type==2));
  

    }

    const loadVideos = async () => {

        const videoz = await axios.get('https://ehcapps.evelynhone.ac.zm/webBackend/home.php?video=1');
        setVideos(videoz.data)
  

    }

    const loadCourses = async () => {

        const course = await axios.get('https://ehcapps.evelynhone.ac.zm/webBackend/home.php?course=1');
        setCourses(course.data)

    }

    const loadVacancy = async () => {

        const vacani = await axios.get('https://ehcapps.evelynhone.ac.zm/webBackend/home.php?vacancy=1');
        setVacancy(vacani.data)
        // console.log(vacani.data)

    }

    var vac = []
    const send = (id)=>{
        
         setMore(vacancy.filter(a=>a.vacid==id))
         da()
         
    }

    const da = () =>{
        console.log(more)
    }

    useEffect(()=>{

        loadNews();
        loadVideos();
        loadCourses();
        loadVacancy();
        da();
        
    }, []);

    function MyVerticallyCenteredModal(props) {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
                  
            <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Vacancy Information
            </Modal.Title>
          </Modal.Header>
          {more.map((res)=>(
          <Modal.Body>
            <h4>{res.PositionName}</h4>
            <h5>{res.Dname}</h5>
            <h6>{res.SectionName}</h6>
            <p>
              Qualification : {res.qualification}
              <br/>
              End date : {res.enddateapplication}
            </p>
            <Button variant="success">Apply</Button>
          </Modal.Body>
          ))}
          <Modal.Footer>
            <Button onClick={props.onHide}>Close</Button>
          </Modal.Footer>
          
                
          </Modal>
        );
      }

    return (
        <div className='home'>
            <HomeCarousel/>
            <Container>

                
                
            <br/>
            <br/>
            <br/>
            <div style={{ backgroundColor: '#FF5733', color: '#fff', padding: '10px', textAlign: 'center' }}>
                <p>
                    DISCLAIMER!!! Please be informed that Evelyn Hone College strictly does not accept any fees or payments through personal accounts. All official payments, including tuition fees and related transactions, should only be made through the designated and secure channels provided by the college.
                </p>
                </div>
                <br/>
            <br/>
            <span className='headText'>Discover More</span>
            <br/>
            <br/>
           
        
            <Row>
                <Col>
                    <div  className='course'> <div class="holder"> Courses </div></div>
                </Col>
                <Col >
                    <div className='aca'> <div>Academics </div></div>
                </Col>
                <Col>
                    <div className='rand'><div>Research & Development </div></div>
                </Col>
            </Row>

            <hr/>
            <br/>
            <br/>
            <span className='headText'>New Courses</span>
            <br/>
            <br/>

                <Row>
                    {
                        course.slice(0,4).map((res)=>(

                            <Col sm={6} md={6}>
                            <div className='cardz'>
                                
                                <img src={res.image} style={{ width:'150px', height:'100px' }}/>
                            <div className='span'>
                            <span className='head'> {res.courseName}</span>
                            <br/>
                            <span>{res.startingDate}</span>
                            <br/>
                            <button> <a href="https://www.evelynhone.ac.zm:444" style={{color:'white'}}>Apply now</a></button>
                            </div>
                            </div>
                        </Col>

                        ))
                    }
                
                   
                </Row>
                    <br/>
                
                   

                <hr/>
            <br/>
            <br/>

            <br/>
            <br/>
            <span className='headText'>Vacancies</span>
            <br/>
            <br/>

            <div class="wrappers">

	        {vacancy.slice(0,4).map((res)=>(
                 <div class="card">
                 <h3 class="card-title">{res.PositionName}</h3>
                 <hr/>
                 <p class="card-content">{res.Dname}
                 <hr/>
                 </p>
                 <button class="card-btn" onClick={() =>{setModalShow(true); send(res.vacid)}}>READ MORE</button>
             </div>
              
            ))
            } 

            <MyVerticallyCenteredModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        />
          
           
        </div>
        <hr/>
        <br/>
        <br/>
            <span className='headText'>News & Events</span>
            <br/>
            <br/>
           

            <Row>
                <Col>
                
                    <span className='head'>News</span>
                    <hr/>

                    {news.slice(0,4).map((res) => (

                        <div className='news low'>
                        <div className='column'>
                        <img src={res.path}/>
                            
                        </div>
                        <div className='colum'>
                         <span className='head'>{substr(res.title,20)}</span>  
                         <br/>  
                         {substr(res.content,23)}
                         <br/>
                        <span className='date'>{res.reg_date}</span>
                        </div>
                        
                    </div>


                     ))}

                </Col>
                <Col>
                    <span className='head'>Video Gallery</span>
                    <hr/>
                    {videos.slice(0,4).map((res) =>(
                    <div className='news'>     
                    <video src={res.path} width="100%" controls="controls" />
                    <span className='head'>{substr(res.title,20)} </span>  
                    <br/>
                    {substr(res.content,23)}
                    <br/>
                        <span className='date'>{res.reg_date}</span>
                    </div>
                    ))}
                </Col>
                <Col>

                    <span className='head'>Events</span>
                    <hr/>

                    {events.map((res) =>(
                    <div className='news low'>
                    
                    <div className='column'>
                    <img style={{width:'100%', height:'100%'}} src={res.path}/>
                        
                    </div>
                    <div className='colum'>
                     <span className='head'>{substr(res.title,20)}</span>  
                     <br/>  
                     {substr(res.content,23)}
                     <br/>
                    <span className='date'>{res.reg_date}</span>
                    </div>
                </div>
                  ))}
                </Col>
            </Row>
        </Container>
        </div>
    )
}
