import React, { useEffect, useState } from 'react'
import './header.css'
import logo from './images/logo.png'
import logos from './images/logoweb.png'
import succ from './images/fin.gif'
import progLoader from './images/programming.gif'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import { Article, Book, BusinessCenter, CalendarToday, CastForEducation, Close, CreditCard, Extension, Facebook, HealthAndSafety, Instagram, Menu, Newspaper, PermMedia, Person, PublishedWithChanges, School, VerifiedUserRounded, WhatsApp } from '@mui/icons-material';
import { FormControl, Button, Row, Table } from 'react-bootstrap'

export default function Header() {

    const showMenu = () =>{
     
        $('.list').slideToggle();
      
    }

    const showRegModo = ()=>{
        $('.regModo').fadeToggle();
        
    }

    function ajaxR(url, data, success) {
        $.ajax({
            type: "post",
            url: url,
            dataType: "json",
            contentType: 'application/json',
            data: JSON.stringify(data),
            success: function (response) {
                success(response);
            }
        });
    }

    


    var subs = []
    var feez = []
    var mode = 0
    var studentNo = ''
    var studentId = 0
    var program = 0

    const getData = ()=>{

        $('#progload').slideDown();

        var stud = $('#studNum').val()
        
        $.getJSON('https://ehcapps.evelynhone.ac.zm:4433/api/payments/'+stud, (response)=>{

            subs = []
            feez = []

            if(response.response == "Nothing found"){
                $('#details').html("Student number does not exist");
                $('#progload').slideUp();
                return;
            }
            var payments = response.payments
            if(payments.length == 0){
                $('#details').html("No payments found for this student");
                $('#progload').slideUp();
                return;
            }

            
            if(payments.filter(a=>a.feeType != 'Payment').length > 0){
                $('#details').html("You have already registered");
                $('#progload').slideUp();
                return;
            }
            
            var out = '<hr><h5>Payments</h5><table class="table"><tr><th>SNo</th><th>Trans Ref</th><th>Paid</th><th>Date</th></tr>'
            var count = 1
            var totoPay = 0;
            payments.map((res)=>{
                out += '<tr><td>'+count+'</td><td>'+res.transRef+'</td><td>K '+res.paid+'</td><td>'+res.dateCaptured.substring(0,10)+'</td></tr>'
                totoPay += parseFloat(res.paid)
                count++
            })
            out += '</table>'
            $('#payments').html(out)
            var put = payments[0].fullName + '<br>'
            put += payments[0].studentNo + '<br>'
            put += payments[0].programName + '<br>'
            put += response.admiDetails.studyMode

            mode = response.studData.studyMode
            studentId = response.studData.id
            program = response.studData.programId
            studentNo = response.studData.studentNo
            var study = response.admiDetails.studyMode

            

            $('#details').html(put)
            var fees = response.fees.filter(a=>a.yearOfStudy == 1 && a.termSemister == 1 && a.nationality == 1 && a.studyMode == study)
            
            var out = '<h5>Fees</h5><table class="table"><tr><th>SNo</th><th>Fee type</th><th>Amount</th></tr>'
            var count = 1
            var total = 0.0
            fees.map((res)=>{
                out += '<tr><td>'+count+'</td><td>'+res.feeType+'</td><td>K '+res.amount+'</td></tr>'
                feez.push(res.feeBillId)
                count++
                total+= parseFloat(res.amount)
            })
            out += '<tr><th colspan="2">Total</th><th>K '+total+'</th></tr></table>'
            $('#fees').html(out)

            if(totoPay < total){
                $('#subjects').html("You do not have enough funds to register");
                $('#progload').slideUp();
                return;
            }

            var subjects = response.subject
            var out = '<h5>Subjects</h5><ol>'
                subjects.map((res)=>{
                    out += '<li>'+res.subjectName+'</li>'
                    subs.push(res.subjectId)
                })
            out += '</ol>'

            $('#subjects').html(out)

            $('#progload').slideUp();
            $('#submitReg').slideDown();
        })

    }

    $(document).on('click', '.avail button', function (e) {
        e.stopImmediatePropagation()
        var id = $(this).attr('id')
        console.log(id)
        var data = {
            studentNo: $('#medStud').val(),
            slotId: parseInt(id)
        }
        ajaxR('https://ehcapps.evelynhone.ac.zm:4433/api/medicalbooking', data, (response)=>{
            $('#medStud').prop('disabled', false)
            $('#medNrc').prop('disabled', false)
            $.getJSON(`https://ehcapps.evelynhone.ac.zm:4433/api/medicalbooking/${$('#medStud').val()}/${$('#medNrc').val()}`,tengani)
    
        })
    })

var availDates = []
const tengani =  (response)=>{
    console.log(response)
    var status = response.status
    if(status == 2){
        $('.medResp').html('Student number or NRC invalid')
        return;
    }

    if(status == 3){
        $('.medResp').html('No payments')
        return;
    }

    if(response.bookings.length != 0){
        var out = '<h5>My booking</h5><table class="table"><tr><th>Sno</th><th>Date</th><th>Start time</th><th>End time</th><th>Status</th></tr>'
        var count = 1
         response.bookings.map((ds)=>{
            response.available.filter(a=>a.slotId == ds.slotId).map((res)=>{
            out += '<tr><td>'+count+'</td><td>'+res.slotDate.substring(0,10)+'</td><td>'+res.startTime+'</td><td>'+res.endTime+'</td><td>'+ds.bookingStatus+'</td></tr>'
            })
            count++
         })
         $('.medResp').html(out+='</table>')
        $('#availDates').html('')
        $('.avail').html('')
        return;
    }

    availDates = [...response.available]
    console.log(availDates)
    var datezz = response.available.map((res)=>res.slotDate.substring(0,10))
    datezz = [...new Set(datezz)]

    var out = '<option value="">select date</option>'
    datezz.map((res)=>{
        out += '<option value="'+res+'">'+res+'</option>'
    })
    $('#availDates').html(out)
    $('.progload').slideUp()
}

    const getMedData = ()=>{
        var stud = $('#medStud').val()
        var nrc = $('#medNrc').val()
        $('.progload').slideDown()
        $.getJSON(`https://ehcapps.evelynhone.ac.zm:4433/api/medicalbooking/${stud}/${nrc}`,tengani)
    }

    const availDat = ()=>{
        var da = $('#availDates').val()
        console.log(da)
        $('#medStud').prop('disabled', true)
        $('#medNrc').prop('disabled', true)
        var out = '<table class="table"><tr><th>Sno</th><th>Start Time</th><th>End Time</th><th>.</th></tr>'
        var count = 1
        availDates.filter(a=>a.slotDate.substring(0,10) == da).map((res)=>{
            out += '<tr><td>'+count+'</td><td>'+res.startTime+'</td><td>'+res.endTime+'</td><td> <button style="border:1px solid black" class="myButon" id="'+res.slotId+'">book</button> </td></tr>'
            count++
        })
        out += '</table>'
        $('.avail').html(out)
    }

    const submitReg = ()=>{

        $('#submitReg').prop('disabled', true);
        $('.loadingSpinner').show();
        console.log(1)

        var data = {
                mode:mode,
                studentId:studentId,
                program:program,
                studentNo:studentNo,
                feez:feez,
                subs:subs
        }
        $.ajax({
            url:'https://ehcapps.evelynhone.ac.zm:4433/api/payments',
            method:'post',
            dataType: "json",
            contentType:'application/json',
            data:JSON.stringify(data),
            success:function(response){
                if(response.status == "1"){
                    $('.regModo').hide();
                    $('.modo').slideDown();
                    setTimeout(function(){
                        $('.modo').slideUp();
                    },5000)
                }else{

                    alert("Something went wwrong")

                }
                $('#submitReg').prop('disabled', false);
                $('.loadingSpinner').hide();
                
            }
        })

    }

    const toggleDiv = ()=>{
        $('.divs').slideToggle()
    }

  

    return (
        <div>

            <div className='modo text-center'>
                    Registration Successfull!!! <img src={succ} style={{width:'50px'}} alt=""/>
                </div>

            <div className='regModo'>
                <div class='modoContent'>
                <Close onClick={showRegModo} style={{color:'red', float:'right'}}/>
                    <h3>2023 Registration</h3>
                    <hr />
                    <div className='text-center'>
                        <Button className='m-1' variant="primary" onClick={toggleDiv}>Registration</Button>
                        <Button className='m-1' variant="primary"  onClick={toggleDiv}>Medicals</Button>
                        </div>
                    <hr />
                    <div id='medExam' className='hid divs'>
                    <Row style={{justifyContent:'center'}}>
                    <FormControl id="medStud" style={{width:'30%', margin:'5px'}} placeholder='Enter student number'/> 
                    <FormControl id="medNrc" style={{width:'30%', margin:'5px'}} placeholder='Enter NRC (without slashes)'/> 
                    <Button style={{width:'25%', margin:'5px'}} onClick={getMedData} variant="dark">Submit</Button>
                    </Row>

                    <div className='progload'>
                        <img src={progLoader} alt="" />
                            Computing...
                    </div>
 
                    <div className='medResp'></div>
                    <div className='bookings'></div>
                    <select onChange={availDat} id="availDates">
                        
                    </select>
                    <div className='avail'></div>

                    </div>

                    <div id='register2023' className='divs'>
                    <Row style={{justifyContent:'center'}}>
                    <FormControl id="studNum" style={{width:'50%', margin:'5px'}} placeholder='Enter student number'/> 
                     <Button style={{width:'25%', margin:'5px'}} onClick={getData} variant="dark">Submit</Button>
                    </Row>
                    <hr />
                    <div className='text-center'>
                        <div id="progload">
                        <img src={progLoader} alt="" />
                            Computing...
                        </div>
                        
                    <Person/>
                    <div id="details"></div>
                    </div>
                    <div id="payments"></div>
                    <div id="fees"></div>
                    <div id="subjects"></div>
                    <Button onClick={submitReg} id="submitReg" style={{display:'none'}} variant="success">Register <span class="loadingSpinner"></span> </Button>
                    </div>
                  
                </div>
            </div>

            <div className='top'>
                <span>
                   
                   <a href="https://www.facebook.com/www.evelynhone.edu.zm/"> <Facebook style={{color:'white'}}/> </a>
                    <a href='https://www.instagram.com/evelynhonecollege/'><Instagram style={{color:'white'}}/></a>
                    <a href='https://twitter.com/evelynhonecolle?lang=en'><WhatsApp style={{color:'white'}}/></a>
                </span>
                <span>
                <button className='apply'> <a style={{color:'white'}} href="https://www.evelynhone.ac.zm:444"> Apply now </a> </button>
                </span>
                
                    
            </div>
            <div className='middle'>
                <span className='hname' style={{margin:'10%'}}>
                    <Link to="/">
                <img src={logos} className='img1' style={{width:'350px'}} />
                <img src={logo} className='img2' style={{width:'60px'}} />
                </Link>
                </span>
                <ul className="list" style={{margin:'10%'}}>
                    <li className='dpbtn'>Admission
                        <ul className='dpcontent'>
                            <li>
                                <Link  to="/admissions/admission/Process">
                            <PublishedWithChanges/>
                            Application process
                            </Link>
                            </li>
                            <li>
                                <Link  to="/admissions/admission/Fees">
                            <CreditCard/>
                            Academic fees
                            
                            </Link>
                            </li>

                            
                            <li>
                            <Link  to="/admissions/admission/Year">
                                <CalendarToday/>
                                Academic calendar
                                </Link>
                                </li>
                        </ul>
                    </li>
                    <li className='dpbtn'>Schools
                        <ul className='dpcontent'>
                            <li>
                                
                                <Link  to="/schools/schools/1">
                                <BusinessCenter/> Business
                                </Link>
                                </li>
                            <li>
                                
                                <Link  to="/schools/schools/2">
                                <HealthAndSafety/>
                                Health & Applied Sciences
                                </Link>
                                </li>
                            <li>
                               
                                <Link  to="/schools/schools/3">
                                <PermMedia/>
                                Media & Communication Studies
                                </Link>
                                </li>
                            <li>
                            
                                <Link  to="/schools/schools/5">
                                <School/>
                                Education and Social Sciences
                                </Link>
                                </li><li>
                                <Link  to="/schools/schools/1006">
                                <Book/>
                                ODFL
                                </Link>
                                </li>
                        </ul>
                    </li>
                    <li className='dpbtn'>Library
                        <ul className='dpcontent'>
                            <li>
                                <Link  to="/library/library/publication">
                                <Newspaper/>
                                Publications
                                </Link></li>
                            <li>
                                <Link  to="/library/library/ebooks">
                                <Book/>
                                Library
                                </Link></li>
                            <li>
                                <Link  to="/library/library/article">
                                <Article/>
                                Articles
                                </Link></li>
                        </ul>

                    </li>
                    <li className='dpbtn'>Portal
                        <ul className='dpcontent'>
                            <li>
                                <School/>
                               <a href='https://www.evelynhone.ac.zm/HoneStudHub/'>Student</a> 
                                
                                </li>
                            <li>
                                <CastForEducation/>
                                <a href='https://www.evelynhone.ac.zm:4409/'>Staff</a>
                                </li>
                                <li>
                                <Book/>
                                <a href='https://www.evelynhone.ac.zm:444/'>Admissions</a>
                                </li>
                        </ul>
                    </li>
                    <li>
                    <Link className='extra'  to="/events/events">Events</Link>

                    </li>
                    <li>
                        <Link className='extra'  to="/about/about">About</Link>
                        
                        </li>
                </ul>
                <Menu onClick={showMenu} className='menuBtn'/>
                
            </div>
            <div className='bottom'>
                <input placeholder='search course' />
                <button>Search</button>
            </div>
        </div>
    )


    
}
